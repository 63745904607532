<template>
  <div style="margin: 20px;">
    <h3>Office Performance Commitment and Review</h3>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <el-form :inline="true" size="small" @submit.native.prevent="icon_search = 'el-icon-loading'; search()">
              <el-row>
                <el-form-item>
                  <el-switch
                    v-model="is_active"
                    :active-value="1"
                    :inactive-value="0"
                    style="margin-top: 5px;"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Active"
                    inactive-text="Inactive"
                    @change="refresh()"
                  />
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item>
                  <el-input v-model="office_name_s" placeholder="Fullname (Acronym)" clearable style="width: 200px;" />
                </el-form-item>
                <el-form-item>
                  <el-tooltip content="Search" placement="top">
                    <el-button
                      :icon="icon_search"
                      type="primary"
                      @click.native.prevent="icon_search = 'el-icon-loading'; search()"
                    />
                  </el-tooltip>
                </el-form-item>
                <el-form-item>
                  <el-tooltip content="Refresh" placement="top">
                    <el-button
                      :icon="icon_refresh"
                      type="info"
                      @click.native.prevent="is_active = 1; icon_refresh = 'el-icon-loading'; refresh();"
                    />
                  </el-tooltip>
                </el-form-item>
              </el-row>
            </el-form>
          </el-row>
          <el-row>
            <el-table
              v-loading="loading"
              ref="opcrPersonnelTable"
              :data="opcr_personnel"
              size="mini"
              style="width: 100%; font-size: 14px;">
              <el-table-column label="Fullname (Acronym)" width="">
                <template slot-scope="scope">
                  <span style="word-break: break-word;">
                    {{ scope.row.fullname + ' (' + scope.row.acronym + ')' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Operation" width="90" align="right">
                <template slot-scope="scope">
                  <div style="margin-top: 8px">
                    <div v-if="scope.row.id !== office_id">
                      <el-tooltip
                        v-if="scope.row.opcr"
                        :key="'opcr1' + scope.row.id"
                        effect="dark"
                        content="View OPCR File (s)"
                        placement="top">
                        <el-badge
                          :key="'opcr2' + scope.row.id"
                          :value="scope.row.opcr"
                          class="item"
                          style="margin-right: 30px;">
                          <el-button
                            :key="'opcr3' + scope.row.id"
                            type="primary"
                            size="mini"
                            @click="viewFiles(scope.row)">
                            <i class="fa fa-list" />
                          </el-button>
                        </el-badge>
                      </el-tooltip>
                      <el-tooltip
                        v-else
                        :key="'opcr4' + scope.row.id"
                        effect="dark"
                        content="View OPCR File (s)"
                        placement="top">
                        <el-button
                          :key="'opcr5' + scope.row.id"
                          type="primary"
                          size="mini"
                          style="margin-right: 15px;"
                          @click="viewFiles(scope.row)">
                          <i class="fa fa-list" />
                        </el-button>
                      </el-tooltip>
                    </div>
                    <div v-else>
                      <el-tooltip
                        :key="'opcr6' + scope.row.id"
                        effect="dark"
                        content="Close OPCR File (s)"
                        placement="top">
                        <el-button
                          :key="'opcr7' + scope.row.id"
                          type="danger"
                          size="mini"
                          icon="el-icon-close"
                          style="margin-right: 15px;"
                          @click="closeFiles"
                        />
                      </el-tooltip>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="meta.per_page"
                :pager-count="5"
                :total="meta.total"
                :current-page.sync="meta.page"
                layout="total, prev, pager, next"
                @current-change="getOPCRPersonnel()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
      <el-col v-if="view_files" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-card shadow="never">
          <el-row>
            <el-col :xs="24" :sm="24" :md="22" :lg="22" :xl="22">
              <h3 style="margin-top: 5px;">
                OPCR Files
                <el-tooltip content="New OPCR" placement="top">
                  <a type="text" style="font-size: 14px; color: #28a73a;" @click="addOPCR()">
                    New <i class="fas fa-plus-square"/>
                  </a>
                </el-tooltip>
              </h3>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2" align="right">
              <!--
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-close"
                circle
                style="position: absolute; top: -13px; right: -13px;"
                @click="closeFiles"
              />
              -->
            </el-col>
          </el-row>
          <el-row>
            <h3 style="margin-top: 0; word-break: break-word;">
              {{ selected_office.fullname }} ({{ selected_office.acronym }})
            </h3>
          </el-row>
          <el-row>
            <el-form :inline="true" size="small" label-position="right">
              <el-form-item>
                <el-date-picker
                  v-model="year_s"
                  type="year"
                  placeholder="Pick a year"
                  style="width:150px; float: right"
                  clearable
                  @change="searchYear()"
                />
              </el-form-item>
              <el-form-item style="float: right;">
                <el-checkbox v-model="naf" label="No Attached File" />
              </el-form-item>
            </el-form>
          </el-row>
          <el-row>
            <el-table
              v-loading="loading1"
              ref="opcrTable"
              :data="opcr_files"
              size="mini"
              style="width: 100%; font-size: 14px;">
              <el-table-column type="expand" label="..." width="48">
                <template slot-scope="props">
                  <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                      <p class="para"><strong>Year: </strong>{{ props.row.year }}</p>
                      <p class="para"><strong>Rating Period: </strong>{{ props.row.rating_period }}</p>
                      <p class="para">
                        <strong>Added by: </strong>
                        <span
                          :title="getFormattedTimeStampDate(props.row.created_at, 'MMMM Do YYYY, hh:mm:ss a')">
                          {{ props.row.add_by_personnel.name }}
                        </span>
                      </p>
                      <p v-if="props.row.updated_by_personnel_id" class="para">
                        <strong>Updated by: </strong>
                        <span
                          :title="getFormattedTimeStampDate(props.row.updated_at, 'MMMM Do YYYY, hh:mm:ss a')"
                          class="para">
                          {{ props.row.update_by_personnel.name }}
                        </span>
                      </p>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column prop="year" label="Year" width="100" />
              <el-table-column label="Rating Period" width="200">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.rating_period === 'January to June'"
                    :key="'opcr8' + scope.row.id"
                    style="border-left: 6px solid #409EFF; padding-left: 5px;">
                    {{ scope.row.rating_period }}
                  </div>
                  <div
                    v-else-if="scope.row.rating_period === 'July to December'"
                    :key="'opcr9' + scope.row.id"
                    style="border-left: 6px solid #E6A23C; padding-left: 5px;">
                    {{ scope.row.rating_period }}
                  </div>
                  <div
                    v-else-if="scope.row.rating_period === 'January to December'"
                    :key="'opcr10' + scope.row.id"
                    style="border-left: 6px solid #F56C6C; padding-left: 5px;">
                    {{ scope.row.rating_period }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.path" style="word-break: break-word">
                    <el-tag
                      type="success"
                      effect="plain"
                      size="medium"
                      style="color: #4CAF50 !important; border-color: #4CAF50 !important; width: 120px;">
                      File Uploaded
                    </el-tag>
                  </div>
                  <div v-else style="word-break: break-word">
                    <el-tag type="danger" effect="plain" size="medium" style="width: 120px;">No Attached File</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Operation (s)" width="130" align="right">
                <template slot-scope="scope">
                  <el-button-group>
                    <el-tooltip
                      v-if="scope.row.path"
                      :key="'opcr11' + scope.row.id"
                      content="View Attached File"
                      placement="top">
                      <el-button
                        :key="'opcr12' + scope.row.id"
                        type="primary"
                        icon="el-icon-document"
                        size="mini"
                        style="margin-right: 10px;"
                        @click="viewAttachmentFile(scope.row.path)"
                      />
                    </el-tooltip>
                    <el-tooltip :key="'opcr13' + scope.row.id" content="Edit" placement="top">
                      <el-button
                        :key="'opcr14' + scope.row.id"
                        type="primary"
                        icon="el-icon-edit"
                        size="mini"
                        style="margin-right: 10px;"
                        @click="editOPCR(scope.row)"
                      />
                    </el-tooltip>
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
            <div align="center" style="margin-top: 10px;">
              <el-pagination
                :page-size="opcr_meta.page"
                :pager-count="5"
                :total="opcr_meta.total"
                :current-page.sync="opcr_meta.page"
                layout="total, prev, pager, next"
                @current-change="getOPCRFiles()"
              />
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="editable"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
      top="5vh">
      <el-row style="margin: -50px 0;">
        <div style="font-size: 18px; font-weight: bold; margin-bottom: 10px; margin-top: 10px;">{{ title_opcr }}</div>
        <div style="font-size: 18px; font-weight: bold; color: #28a73a;">
          {{ selected_office.fullname }} ({{ selected_office.acronym }})
        </div>
        <el-divider />
        <p>
          <strong>Note:</strong>
          Fields marked with asterisk (<span style="color: #f56c6c; font-weight: bold;">*</span>) are required.
        </p>
        <el-form ref="selected_opcr" :model="selected_opcr" :rules="rules" size="small">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="Year" prop="year">
                <el-date-picker
                  v-model="selected_opcr.year"
                  :clearable="false"
                  type="year"
                  placeholder="Please pick a year"
                  style="width:100%"
                />
              </el-form-item>
              <el-form-item label="Rating Period" prop="rating_period">
                <el-select
                  v-model="selected_opcr.rating_period"
                  :clearable="false"
                  placeholder="Please select rating period"
                  style="width: 100%">
                  <el-option value="January to June" label="January to June">January to June</el-option>
                  <el-option value="July to December" label="July to December">July to December</el-option>
                  <el-option value="January to December" label="January to December">January to December</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item ref="path" label="Attached File">
                <opcr-uploader-component
                  v-if="editable"
                  :path.sync="selected_opcr.path"
                  class="my-upload-job"
                  @setAttachment="selected_opcr.path = $event"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-divider />
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="submitForm()">Save</el-button>
        <el-button size="small" @click="closeForm()">Cancel</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeAttachmentFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { OPCR } from '@/api/records/opcr'
import { OpcrPersonnel } from '@/api/records/opcr/opcr_personnel'
import { Notification } from 'element-ui'
import IPCROPCRUploaderComponent from '../../layout/components/Records/IPCROPCRUploaderComponent'

export default {
  name: 'OPCR',
  components: {
    'opcr-uploader-component': IPCROPCRUploaderComponent
  },
  data() {
    return {
      office_name_s: null,
      is_active: 1,
      loading: false,
      opcr_personnel: [],
      meta: {
        page: 1
      },
      icon_search: 'el-icon-search',
      icon_refresh: 'el-icon-refresh',
      view_files: false,
      selected_office: {},
      year_s: null,
      naf: false,
      path_s: null,
      office_id: null,
      loading1: false,
      opcr_files: [],
      opcr_meta: {
        page: 1
      },
      rules: {
        year: [
          { required: true, message: 'Year is required', trigger: 'blur' }
        ],
        rating_period: [
          { required: true, message: 'Rating Period is required', trigger: 'blur' }
        ]
      },
      title_opcr: null,
      selected_opcr: {},
      editable: false,
      base_url: this.$store.getters.base_url,
      view_file: null,
      view_attach_form: false
    }
  },
  watch: {
    naf: function() {
      this.year_s = null
      if (this.naf) this.path_s = 1
      else this.path_s = null
      this.opcr_meta.page = 1
      this.getOPCRFiles()
    }
  },
  created: function() {
    this.getOPCRPersonnel()
  },
  methods: {
    getOPCRPersonnel() {
      this.loading = true
      OpcrPersonnel({
        page: this.meta.page,
        is_active: this.is_active,
        office_name: this.office_name_s
      }).then(res => {
        this.opcr_personnel = res.data.opcr_personnel
        this.meta = res.data.meta
        this.loading = false
        this.icon_search = 'el-icon-search'
        this.icon_refresh = 'el-icon-refresh'
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
    search() {
      this.selected_office = {}
      this.office_id = null
      this.view_files = false
      this.year_s = null
      this.naf = false
      this.path_s = null
      this.meta.page = 1
      this.getOPCRPersonnel()
    },
    refresh() {
      this.view_files = false
      this.selected_office = {}
      this.office_name_s = null
      this.office_id = null
      this.year_s = null
      this.naf = false
      this.path_s = null
      this.meta.page = 1
      this.getOPCRPersonnel()
    },
    viewFiles(obj) {
      this.year_s = null
      this.naf = false
      this.path_s = null
      this.selected_office = Object.assign({}, obj)
      this.office_id = this.selected_office.id
      this.view_files = true
      this.opcr_meta.page = 1
      this.getOPCRFiles()
      // console.log('viewFiles -> selected_office:', this.selected_office)
    },
    closeFiles() {
      this.year_s = null
      this.naf = false
      this.path_s = null
      this.selected_office = {}
      this.office_id = null
      this.view_files = false
    },
    getOPCRFiles() {
      this.loading1 = true
      OPCR({
        page: this.opcr_meta.page,
        office_id: this.office_id,
        year: this.year_s,
        path: this.path_s
      }).then(res => {
        this.opcr_files = res.data.opcr
        this.opcr_meta = res.data.meta
        this.loading1 = false
      }).catch(error => {
        console.log(error)
        this.loading1 = false
      })
    },
    searchYear() {
      if (this.year_s) this.year_s = this.getFormattedYear(this.year_s)
      this.opcr_meta.page = 1
      this.getOPCRFiles()
    },
    addOPCR() {
      this.title_opcr = 'New OPCR'
      this.selected_opcr.office_id = this.office_id
      this.selected_opcr.orig_path = null
      this.editable = true
    },
    editOPCR(row) {
      this.title_opcr = 'Edit OPCR'
      this.selected_opcr.id = row.id
      this.selected_opcr.rating_period = row.rating_period
      this.selected_opcr.year = "'" + row.year + "'"
      this.selected_opcr.office_id = row.office_id
      this.selected_opcr.path = row.path
      this.selected_opcr.orig_path = row.path
      this.selected_opcr = Object.assign({}, this.selected_opcr)
      this.editable = true
      // console.log('editOPCR -> selected_opcr:', this.selected_opcr)
    },
    submitForm() {
      this.$refs['selected_opcr'].validate((valid) => {
        if (valid) {
          this.selected_opcr.year = this.getFormattedYear(this.selected_opcr.year)
          var action = this.selected_opcr.id ? 'update' : 'create'
          OPCR(this.selected_opcr, action).then(res => {
            if (!res.data.success) {
              Notification.success({
                title: 'Success',
                message: 'OPCR has been saved successfully',
                duration: 5 * 1000
              })
              this.closeForm()
              this.getOPCRPersonnel()
              this.getOPCRFiles()
            } else {
              Notification.error({
                title: 'Error',
                message: 'Data already exists',
                duration: 5 * 1000
              })
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          return false
        }
      })
    },
    closeForm() {
      this.$refs['selected_opcr'].resetFields()
      this.selected_opcr = {}
      this.editable = false
    },
    viewAttachmentFile(path) {
      this.view_file = this.base_url + '/records/opcr/download/' + path
      this.view_attach_form = true
    },
    closeAttachmentFile() {
      this.view_file = null
      this.view_attach_form = false
    },
    getFormattedYear(year) {
      return this.$moment(new Date(year)).format('YYYY')
    },
    getFormattedTimeStampDate(date, format = 'YYYY-MM-DD') {
      return this.$moment(date).format(format)
    }
  }
}

</script>

